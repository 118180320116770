import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';

import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import { SharedChipButtonVariant, SharedIconType } from '@ess/shared/utils/models';

import { AbstractSharedUiButtonComponent } from '../shared/shared-ui-button.component.abstract';

@Component({
  selector: 'ess-shared-ui-chip-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButton, SharedUiIconDirective],
  template: `
    <button mat-button color="primary" [disabled]="disabled">
      <div class="shared-button-content">
        @if (icon) {
        <i [essIcon]="icon"></i>
        }
        <div class="shared-button-label">
          <ng-content></ng-content>
        </div>
        @if (iconRight) {
        <i [essIcon]="iconRight"></i>
        }
      </div>
    </button>
  `,
})
export class SharedUiChipButtonComponent extends AbstractSharedUiButtonComponent {
  @Input() variant: SharedChipButtonVariant = 'primary';
  @Input() icon: SharedIconType | string | null = null;
  @Input() iconRight: SharedIconType | string | null = null;

  @HostBinding('class')
  private get cssSetup(): string[] {
    return ['shared-chip-button', `variant-${this.variant}`];
  }
}
