import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import { SharedIconButtonColor, SharedIconButtonSize, SharedIconSize, SharedIconType } from '@ess/shared/utils/models';

import { AbstractSharedUiButtonComponent } from '../shared/shared-ui-button.component.abstract';

@Component({
  selector: 'ess-shared-ui-icon-button',
  standalone: true,
  imports: [MatButtonModule, SharedUiIconDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (icon) {
    <button mat-icon-button [color]="color" [disabled]="disabled">
      <i [essIcon]="icon" [essIconSize]="_ICON_SIZE_MAP[size]"></i>
    </button>
    }
  `,
})
export class SharedUiIconButtonComponent extends AbstractSharedUiButtonComponent {
  @Input() icon: SharedIconType | string | null = null;
  @Input() size: SharedIconButtonSize = 'xs';
  @Input() color?: SharedIconButtonColor;
  @Input() noBackground = false;

  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  get wrapperSize(): number {
    return this._BUTTON_SIZE_MAP[this.size];
  }

  @HostBinding('class')
  private get cssSetup(): string[] {
    return this.icon
      ? ['shared-icon-button', `size-${this.size}`, `background-${this.noBackground ? 'none' : 'full'}`]
      : ['hidden'];
  }

  protected readonly _ICON_SIZE_MAP: { [size in SharedIconButtonSize]: SharedIconSize } = {
    xs: 'md',
    sm: 'xl', // SMALL
    md: 'xxl', // NAVIGATION
    lg: 'xxl', // REGULAR
  };

  protected readonly _BUTTON_SIZE_MAP: { [size in SharedIconButtonSize]: number } = {
    xs: 16,
    sm: 28, // SMALL
    md: 40, // NAVIGATION
    lg: 48, // REGULAR
  };
}
