import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import {
  SharedButtonIconPosition,
  SharedButtonSize,
  SharedButtonVariant,
  SharedIconType,
} from '@ess/shared/utils/models';

import { AbstractSharedUiButtonComponent } from '../shared/shared-ui-button.component.abstract';

@Component({
  selector: 'ess-shared-ui-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, SharedUiIconDirective],
  templateUrl: './shared-ui-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiButtonComponent extends AbstractSharedUiButtonComponent {
  @Input() size: SharedButtonSize = 'lg';
  @Input() variant: SharedButtonVariant = 'primary';
  @Input() icon: SharedIconType | string | null = null;
  @Input() iconPosition: SharedButtonIconPosition = 'left';

  @HostBinding('class.w-full')
  @Input()
  fill = false;

  @HostBinding('class')
  private get cssSetup(): string[] {
    return ['shared-button', `size-${this.size}`, `variant-${this.variant}`, `icon-position-${this.iconPosition}`];
  }
}
