import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { SharedExpandButtonVariant } from '@ess/shared/utils/models';

import { AbstractSharedUiButtonComponent } from '../shared/shared-ui-button.component.abstract';

@Component({
  selector: 'ess-shared-ui-expand-button',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `+{{ count }}`,
})
export class SharedUIExpandButtonComponent extends AbstractSharedUiButtonComponent {
  @Input() count = 1;
  @Input() variant: SharedExpandButtonVariant = 'number';

  @HostBinding('class.error')
  @Input()
  error: boolean | null = false;

  @HostBinding('class')
  private get cssSetup(): string[] {
    return ['shared-expand-button', `variant-${this.variant}`];
  }
}
