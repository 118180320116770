import { Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
  template: '',
})
export abstract class AbstractSharedUiButtonComponent {
  @HostBinding('class.disabled')
  @Input()
  disabled: boolean | null = false;

  constructor(private readonly __element: ElementRef) {
    this.__element.nativeElement.addEventListener('click', (e: Event) => {
      if (this.disabled) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    });
  }
}
