@switch (variant) { @case ('primary') {
<button mat-flat-button color="primary" [disabled]="disabled">
  <ng-container *ngTemplateOutlet="content" />
</button>
} @case ('secondary') {
<button mat-flat-button color="accent" [disabled]="disabled">
  <ng-container *ngTemplateOutlet="content" />
</button>
} @case ('outline') {
<button mat-stroked-button color="primary" [disabled]="disabled">
  <ng-container *ngTemplateOutlet="content" />
</button>
} @case ('text') {
<button mat-button color="primary" [disabled]="disabled">
  <ng-container *ngTemplateOutlet="content" />
</button>
} @case ('warn') {
<button mat-stroked-button color="warn" [disabled]="disabled">
  <ng-container *ngTemplateOutlet="content" />
</button>
} }

<ng-template #content>
  <div class="shared-button-content">
    @if (icon) {
    <i [essIcon]="icon"></i>
    }
    <div class="shared-button-label">
      <ng-content />
    </div>
  </div>
</ng-template>
